import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/figure.png'
import AVTR2 from '../../assets/figure2.jpg'
import AVTR3 from '../../assets/figure3.jpg'
import AVTR4 from '../../assets/figure4.jpg'

//import Swiper core and required modules
import {Pagination, Navigation} from 'swiper';

import {Swiper, SwiperSlide} from 'swiper/react';

//Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVTR2,
    name: 'Patient review from HealthGrades',
    review:  'Knowledgeable, experienced and I feel comfortable with her during all my appointments. She listens to my concerns and provides sound medical care. If you are looking for a doctor whose medical knowledge is extensive, treats the whole person, and takes time to be caring even while maintaining a very efficient office, then Dr. Walid is someone you should consider visiting.'
  },
  {
    avatar: AVTR1,
    name: 'Patient review from HealthGrades',
    review: 'Dr. Walid is very professional , with good bedside manners, very caring and always has a smile on her face.'
  },

  {
    avatar: AVTR3,
    name: 'Patient review from HealthGrades',
    review: 'Very little wait time. Spent time with me and listened to everything I needed to say. I felt she really cared and wanted to help me get the best treatment possible. I trust her decisions completely and would recommend her to all.'
  },
  {
    avatar: AVTR4,
    name: 'Patient review from HealthGrades',
    review: 'I had been in three hospitals for severe leg pain and difficulty walking. They told me that I was fine as my previous doctor had. I changed to Dr. walid. She determined the problem immediately and it was a complex one. Without her care I would not have my legs today. She immediately sent me to the necessary specialists. She is extremely knowledgeable... '
  }

]

const Testimonials = () => {
  return (
    <section id = 'testimonials'>
      <h5>Reviews from patients</h5>
      <h2>Testimonials</h2>
      <Swiper className = "testimonials__container"
      modules={[Pagination]} spaceBetween={40}
      slidesPerView={1}
      pagination={{clickable: true}}>
       {
        data.map(({avatar, name, review}, index) => {
          return (
            <center><SwiperSlide key = {index} className = "testimonial">
              <div className = "client__avatar">
                <img src= {avatar} />
              </div>
              <h5 className = 'client__name'>{name}</h5>
              <small className = 'client__review'>{review}</small>
            </SwiperSlide></center>
          )
        })
       }
        
        
      </Swiper>
    </section>
  )
}

export default Testimonials