import React from 'react'

const CTA = () => {
  return (
    <div className ='cta'>
    <a href="#contact" className='btn btn-primary'>Contact us</a>
    </div>
  )
}

export default CTA