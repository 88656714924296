import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
      <a href= '#' className='footer__logo'>Primary Family Medicine</a>

      <ul className = 'permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#theDoctor'>The Doctor</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>
      <div className = "footer__socials">
        <a href="https://facebook.com/profile.php?id=100067094586457"><FaFacebookF/></a>
      </div>
      <div className ="footer__copyright">
        <small>&copy; Primary Family Medicine. All rights reserved</small>
      </div>
      </footer>
  )
}

export default Footer