import React from 'react'
import './contact.css'
import {MdEmail} from 'react-icons/md'
import {TbMessage2Share} from 'react-icons/tb'
import {FiPhoneCall} from 'react-icons/fi'
import {useRef} from 'react'
import emailjs from 'emailjs-com'


const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_p46b0il', 'template_m1r3bid', form.current, 'RgJ4cbFJQ9ZcrEZEi')

    e.target.reset();
  };
  return (
    <section id = 'contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="contact__container">
        <div className = "contact__options">
          <article className = "contact__option">
            <MdEmail className = 'contact__option-icon'/>
            <h4>Email</h4>
            <h5>primaryfamilymedicineclinic@gmail.com</h5>
            <a href="mailto:primaryfamilymedicineclinic@gmail.com" target="_blank">Send a message</a>
          </article>
          <article className = "contact__option">
            <TbMessage2Share className = 'contact__option-icon'/>
            <h4>Fax</h4>
            <h5>478-922-6686</h5>
            <a href="#contact">Send a message</a>
          </article>
          <article className = "contact__option">
            <FiPhoneCall className = 'contact__option-icon'/>
            <h4>Phone Number</h4>
            <h5>478-922-6685</h5>
            <a href="tel: 478-922-6685">Send a message</a>
          </article>
        </div>
        <form ref = {form} onSubmit ={sendEmail}
        action="">
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type ="email" name='email' placeholder='Your Email' required />
          <textarea name= "message" rows="7" placeholder='Your Message' default="blank"required>
          </textarea>
          <button type='submit' className= 'btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact