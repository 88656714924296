import React from 'react'
import './about.css'
import InsideOffice from "../../assets/inside_office_room.png"


const About = () => {
  return (
    <section id = 'about'> 
    <h5>The Office</h5>
    <h2>About</h2>
    <div className = "about__container">
      <div className = "about__me">
        <div className = "about__me-image">
          <img src = {InsideOffice} alt = "InsideOffice" />
        </div>
      </div>
      <div className="about__content">
          <p>
            Primary Family Medicine is a private family medicine clinic located at 110 Woodcrest Blvd 
            in Warner Robins, Geogia. Patients receive same day services in addition to scheduled appointments, and walk-ins
            are welcome. Most insurances are accepted. 
            It is open from 9-4 on Monday, Wednesday, and Thursday, and from 9-5 on Tuesday. At this clinic,
            everyone is treated like family, and you will always walk into a friendly and welcoming environment.
          </p>
          <a href ="#contact" className = 'btn btn-primary'>Book an appointment</a>
      </div>
    </div>

    
    </section>
  )
  }

export default About