import React from 'react'
import './services.css'
import {BsCheck} from 'react-icons/bs'

const Services = () => {
  return (
    <section id = 'services'>
      <h5>What We Offer</h5>
      <h2>Services</h2>
      <div className = "services__container">
        <article className = "service">
          <div className="service__head">
            <h3>Come on in for...</h3>
          </div>
          <ul className = 'service__list'>
            <li>
            <BsCheck className = 'service__list-icon' />
            <p> Chronic medical conditions</p>
            </li>
            <li>
            <BsCheck className = 'service__list-icon' />
            <p> Well woman exam</p>
            </li>
            <li>
            <BsCheck className = 'service__list-icon' />
            <p> Health maintenance</p>
            </li>
          </ul>
        </article>
        <article className = "service">
          <div className="service__head">
            <h3>For people of all ages...</h3>
          </div>
          <ul className = 'service__list'>
            <li>
            <BsCheck className = 'service__list-icon' />
            <p> Preventative medicine</p>
            </li>
            <li>
            <BsCheck className = 'service__list-icon' />
            <p> Sports Physicals</p>
            </li>
            <li>
            <BsCheck className = 'service__list-icon' />
            <p> Telemedicine visits</p>
            </li>
            <li>
            <BsCheck className = 'service__list-icon' />
            <p> Weight loss</p>
            </li>
          </ul>
        </article>
        <article className = "service">
          <div className="service__head">
            <h3>And more...</h3>
          </div>
          <ul className = 'service__list'>
            <li>
            <BsCheck className = 'service__list-icon' />
            <p> Pulmonary function test</p>
            </li>
            <li>
            <BsCheck className = 'service__list-icon' />
            <p> EKG</p>
            </li>
            <li>
            <BsCheck className = 'service__list-icon' />
            <p> Acute care</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services
