import React from 'react'
import './theDoctor.css'
import theDoctor from '../../assets/doctor.jpg'
import {MdWork} from 'react-icons/md'
import {MdOutlineLanguage} from 'react-icons/md'
import {FaUniversity} from 'react-icons/fa'
function TheDoctor() {
  return (
    <section id = 'theDoctor'>
    <h5> Get To Know</h5>
    <h2> About the Doctor</h2>
    <div className = "about__container">
      <div className = "about__me">
        <div className = "about__me-image">
          <img src = {theDoctor} alt = "the Doctor" class ="smaller" />
        </div>
      </div>
      <div className="about__content">
          <div className="about__cards">
            <article className = 'about__card'>
                <MdWork className = 'about__icon'/>
                <h5>Experience</h5>
                <small> 20+ years practicing</small>
            </article>
            <article className = 'about__card'>
                <FaUniversity className = 'about__icon'/>
                <h5>Education</h5>
                <small> Tishreen University</small>
            </article>
            <article className = 'about__card'>
                <MdOutlineLanguage className = 'about__icon'/>
                <h5>Languages</h5>
                <small> Speaks English, Arabic, and Russian fluently</small>
            </article>
          </div>
          <p>
            Dr. Ferial Walid has been practicing in Middle Georgia for over 20 years. 
            She graduated from Tishreen University in Damascus, Syria, in 1997, moving to the United States in 1998 
            where she completed her training at the Medical Center of Central Georgia/Mercer University. She is the loving mother of three young girls, and in her free time,
            she likes to run, read, and cook. 
          </p>
          <a href ="#contact" className = 'btn btn-primary'>Let's Talk</a>
      </div>
    </div>
    </section>
  )
}

export default TheDoctor